import logo from "./logos/logo-njio_logo.svg";
import "./App.css";

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>Coming soon!</p>
				<a
					className="App-link"
					href="https://nickjonsson.io"
					target="_blank"
					rel="noopener noreferrer"
				>
					@nickjonsson.io
				</a>
			</header>
		</div>
	);
}

export default App;
